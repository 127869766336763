<template>
  <div class="w-screen h-screen table-cell align-middle text-center">
    <div>ページが見つかりませんでした。</div>
    <div>
      <button
        @click="router.replace({ name: 'Login' })"
        class="bg-white shadow"
      >
        Loginに戻る
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRouter()
    return {
      router
    }
  }
})
</script>